// ? maybe? "use client";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "@/utils/tw";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import type { Icons } from "./Icon";
import { Icon } from "./Icon";
const buttonVariants = cva("button inline-flex cursor-pointer items-center justify-center gap-2.5 border ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:text-white", {
  variants: {
    rounded: {
      true: "rounded-full",
      false: "rounded-lg"
    },
    bordered: {
      true: "border-black dark:border-white",
      false: "border-transparent"
    },
    size: {
      default: "h-10 px-4 py-1 text-sm",
      sm: "px-3 py-1 text-sm",
      xs: "gap-1 px-2 py-1 text-sm",
      lg: "px-4 py-3 text-base",
      xl: "px-8 py-4 md:px-10 md:text-xl"
    },
    variant: {
      black: "dark bg-black text-white",
      primary: "bg-primary hover:bg-primary-darker",
      "primary-light": "bg-primary-light hover:bg-primary-lighter dark:text-black",
      "primary-lighter": "border-transparent bg-primary-lighter hover:shadow",
      "primary-dark": "bg-primary-dark text-white hover:bg-primary-darker",
      secondary: "bg-secondary",
      "secondary-light": "bg-secondary-light",
      white: "bg-white text-primary-text dark:text-black",
      grey: "bg-background text-inactive-gray",
      destructive: "border-destructive text-destructive hover:bg-destructive hover:text-white",
      "outline-inverted": "border-white text-white hover:bg-primary-dark",
      "outline-muted": "border-border hover:bg-white",
      ghost: "bg-transparent hover:translate-x-0 hover:translate-y-0 hover:bg-black/10 hover:drop-shadow-none dark:border-transparent dark:text-white",
      link: "mx-0 border-0 px-0 text-purple underline underline-offset-4 hover:translate-x-0 hover:translate-y-0 hover:drop-shadow-none dark:border-0",
      // Custom variants
      purple: "dark bg-purple text-white",
      "purple-lighter": "bg-purple-5 text-white",
      "purple-light": "bg-purple-6 text-purple",
      yellow: "dark bg-yellow text-purple",
      "yellow-light": "bg-yellow-2 text-purple",
      beige: "bg-beige-2 text-black"
    },
    fancy: {
      true: "fancy-button filter-none ease-linear hover:drop-shadow-[4px_4px_0px_black]",
      false: ""
    }
  },
  defaultVariants: {
    bordered: false,
    variant: "black",
    size: "default",
    fancy: false,
    rounded: false
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: keyof typeof Icons;
  loading?: boolean;
  iconPlacement?: "left" | "right";
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  fancy = false,
  variant = "black",
  iconPlacement = "right",
  rounded,
  size = "default",
  icon,
  loading = false,
  bordered = false,
  disabled = false,
  asChild = false,
  // asChild can only be used in side a client component
  children,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  const renderedIcon = icon || loading ? <Icon icon={loading ? "loading" : icon} size={"x-small"} variant="ghost" /> : null;
  return <Comp className={cn(buttonVariants({
    size,
    fancy: icon && !children ? false : fancy,
    bordered,
    rounded,
    variant
  }), icon && !children ? "flex items-center justify-center px-0 py-0" : "", icon && !children ? size === "default" ? "h-10 w-10" : size === "sm" ? "h-8 w-8" : size === "xs" ? "h-6 w-6" : "h-12 w-12" : "", className)} ref={ref} type="button" {...props} disabled={disabled || loading}>
        {iconPlacement === "left" ? renderedIcon : null}
        {children} {iconPlacement === "right" ? renderedIcon : null}
      </Comp>;
});
Button.displayName = "Button";
const ButtonGroup = ({
  children,
  className,
  variant = "default"
}: {
  children: React.ReactNode[];
  className?: string;
  variant?: "bordered" | "default";
}) => {
  return <div className={cn("flex flex-nowrap gap-2.5 self-start overflow-auto whitespace-nowrap rounded-lg p-1", variant === "default" && "bg-beige-2", variant === "bordered" && "border border-inactive-gray", className)} data-sentry-component="ButtonGroup" data-sentry-source-file="Button.tsx">
      {children}
    </div>;
};
export { Button, buttonVariants, ButtonGroup };